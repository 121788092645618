import React, { useEffect, useState } from 'react';
import Rose from '../Assets/Images/Root/Rose.png';

function Home() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='WelcomeCenter'>
      <h1 className={`welcomeMessage ${isVisible ? 'visible' : ''}`}>Welcome!</h1>
      <img className="aRose" src={Rose} alt='A Rose with Stem and Leaves.'/>
    </div>
  );
}

export default Home;
