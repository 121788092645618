import Cards from '../Components/Cards/Cards';


function Work() {
  return (
    <>
    <Cards/>
    {/* <i className="fa-brands fa-react iconProjects" />
    <i class="fa-brands fa-html5 iconProjects"></i>
    <i class="fa-brands fa-css3-alt iconProjects"></i>
    <i class="fa-solid fa-database iconProjects"></i> */}
    </>
  )
}

export default Work