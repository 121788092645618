import { Link } from 'react-router-dom';
import './NavBar.css';
import AnimatedBackground from '../AnimatedBackground/AnimatedBackground';
import NameLogo from '../../Assets/Images/NameLogo.png';

const NavBar = () => {
  return (
    <nav className="navbar navContainer">
      <div className="navbar-left">
        <Link to="/"><img className='navbar-name' src={NameLogo} alt="Name Logo" /></Link>
      </div>
      <AnimatedBackground />
      <div className="navbar-right">
        <ul className="navbar-links">
          <li><Link to="/Work">Work</Link></li>
          <li><Link to="/About">About</Link></li>
          <li><Link to="/Contact">Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
