import React, { useEffect } from 'react';
import './AnimatedBackground.css';

const AnimatedBackground = () => {
  useEffect(() => {
    const numDots = 75;
    const colors = ['#3485b9', '#74eeb7'];
    const dotContainer = document.querySelector('.dot-container');

    for (let i = 0; i < numDots; i++) {
      const dot = document.createElement('div');
      dot.className = 'dot';
      dot.style.setProperty('--dot-color', colors[i % colors.length]);
      dot.style.setProperty('--move-x', `${Math.random() * 200 - 100}%`);
      dot.style.setProperty('--move-y', `${Math.random() * 200 - 100}%`);
      dot.style.setProperty('--duration', `${(Math.random() * 5 + 2) / 2}s`); // Adjust duration
      dot.style.top = `${Math.random() * 100}%`;
      dot.style.left = `${Math.random() * 100}%`;
      dotContainer.appendChild(dot);
    }
  }, []);

  return <div className="dot-container"></div>;
};

export default AnimatedBackground;
