import { useForm, ValidationError } from "@formspree/react";
import Map from "../Assets/Images/Contact/Map.png";

const Contact = () => {
  const [state, handleSubmit] = useForm("mvgpkovw");
  if (state.succeeded) {
    return <>
          <p className="thankYouMessage">Challenge Accepted!</p>
          <br />
          <p className="thankYouMessage">I'll be in Touch Soon..</p>;
          </>
  }
  return (
    <>
      <h1 className="contactHeader">
        Ready for the <span className="challengeWord">CHALLENGE!</span>
      </h1>

      <div className="contactContainer">
        <div className="leftInfo">
          <img
            src={Map}
            className="mapImage"
            alt="a location of where developer is located."
          />
          <span className="localCodeMessage">Locally Coded in Augusta</span>
        </div>
        <div className="rightForms">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input id="name" type="text" name="name" required />
            </div>
            <ValidationError prefix="Name" field="name" errors={state.errors} />

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input id="email" type="email" name="email" required />
            </div>
            <ValidationError prefix="Email" field="email" errors={state.errors} />

            <div className="form-group">
              <label htmlFor="phone">Contact #:</label>
              <input id="phone" type="tel" name="phone" required />
            </div>
            <ValidationError prefix="Phone" field="phone" errors={state.errors} />

            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <textarea id="message" name="message" required></textarea>
            </div>
            <ValidationError prefix="Message" field="message" errors={state.errors} />

            <button type="submit" disabled={state.submitting}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
