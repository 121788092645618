import Work from "./Work.json";
import "./Cards.css"


const Cards = () => {

    return (
        <div className="cards-container">
        {Work.map((project) => (
          <a key={project.id} href={project.link} target="_blank" rel="noopener noreferrer" className="card-anchor">
            <div className="card">
            <p>{project.title}</p>
            {/* <p>{project.technology}</p> */}
            <img src={project.image} alt="testing area for cards"/>
            <p>{project.description}</p>
          </div>
          </a>
        ))}
      </div>
    )
}

export default Cards;