import NotFoundSpace from '../Assets/Images/NotFoundSpace.png'

function NotFound() {
  return (
    <div className='notFound'>
    <img className='notFoundSpace' src={NotFoundSpace} alt="404 Error Message. Try another web page or link." />
    </div>
  )
}

export default NotFound