
function About() {
  return (
    <>
    <div className='aboutContainer'>
        <p className='aboutParagraph'>I'm a <span className='wordOne'>Software Developer</span> specializing in high-quality <span className='wordTwo'>web development</span> for local gigs and small businesses at an affordable price.
        <br />
        All of my work consists of <span className='wordThree'>custom solutions</span>, crafted from our discussions and tailored to your specific needs. I will investigate your industry and competition to help digitize your presence on the modern web.
        <br />
        An <span className='wordFour'>Air Force</span> Veteran and <span className='wordFive'>Arizona State University</span> Engineering student with a passion for turning your dreams into reality through code.</p>
        <p className='aboutParagraph'>Please explore my work and give me the opportunity to take charge of your business's web needs today!</p>


    </div>
    </>
  )
}

export default About