import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Work from './Pages/Work';
import NotFound from './Pages/NotFound';
import NavBar from './Components/NavBar/NavBar';

function App() {
  return (
    <Router>
      <NavBar />
      <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/work" element={<Work />}/>
        <Route path="about" element={<About />}/>
        <Route path="contact" element={<Contact />}/>
        <Route path="*" element={<NotFound />}/>
      </Routes>
      </main>
    </Router>
  );
}

export default App;
